const firebase = require("firebase/app")
// required for side-effects according to Larry and Sergei
require("firebase/firestore")

let firestore

if (firebase.initializeApp !== undefined) {
  firebase.initializeApp({
    apiKey: "AIzaSyDMSNEeRK6MRoHmdPfGXRrylx_Ln_fMTRw",
    authDomain: "meetcam-website.firebaseapp.com",
    databaseURL: "https://meetcam-website.firebaseio.com",
    projectId: "meetcam-website",
    storageBucket: "meetcam-website.appspot.com",
    messagingSenderId: "626465589098",
    appId: "1:626465589098:web:09b7477b90e912644f0713"
  })

  firestore = firebase.firestore()
}

export default firestore

