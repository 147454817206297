import React from "react"

export default () => (
  <div className="my-4 sm:mt-10 h-10 flex flex-col justify-center">
    <div className="bg-white h-10">
      < div className="max-w-screen-xl mx-auto sm:pt-12 px-4 overflow-hidden sm:px-6 lg:px-8" >
        <div className="mt-0 sm:mt-8">
          <p className="text-center text-base leading-6 text-gray-400">
            &copy; 2020 Daniel Owsiański, Zbigniew Sobiecki. All rights
            reserved.
          </p>
        </div>
      </div >
    </div >
  </div >
)
