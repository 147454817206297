import React from "react"
import Pattern from "./pattern"
import logo from '../data/images/logo.gif'
import Typewriter from 'typewriter-effect'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const CTA = (
  <div className="text-center">
    <div className="">
      Download Meet Cam β <br className="block md:hidden" /> for macOS
    </div>
  </div>
)

const PLATFORMS = ['Zoom', 'Google Meet', 'GoToMeeting', 'Blue Jeans', 'Facebook Messenger', 'Houseparty', 'Jitsi', 'WebinarNinja', 'Vidyo']

export default () => {

  return (
    <div className="h-90vh">
      <main className="flex flex-col h-90vh justify-around">

        <div className="hidden sm:block">
          <Pattern className="mt-40" />
        </div>

        <div className="max-h-10perc flex flex-row justify-center">
          <img src={logo} className="max-h-10perc" alt="Meet Cam logo" />
        </div>

        <div className="flex flex-col justify-around text-center items-center my-10 sm:my-5">
          <div className="sm:max-w-3/4 sm:w-3/4 sm:mt-0 w-full">
            <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-12 md:leading-20 md:text-6xl">
              Add
              <span className="ml-2 text-indigo-600">
                timer,<br className="block md:hidden" /> agenda, <span className="mr-1 hidden sm:inline">notes, </span> gifs
              </span> and<br className="block sm:hidden" /> more 🤩 to your
              <span className="text-indigo-600">
                <Typewriter
                  options={{
                    strings: PLATFORMS,
                    autoStart: true,
                    loop: true,
                    delay: 75,
                    deleteSpeed: 25,
                    wrapperClassName: "inline text-indigo-600",
                  }}
                />
              </span>
              meetings
            </h1>
          </div>
        </div>

        <div className="sm:flex sm:flex-row sm:justify-center md:mb-30">
          <div className="rounded-md shadow">
            <AnchorLink to="/#download" title="Download">
              <div
                id="main-hero-download-button"
                className="w-full flex items-center justify-center px-8 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out py-2 md:py-4 md:text-lg md:px-10"
              >
                {CTA}
              </div>
            </AnchorLink>
          </div>
        </div>
      </main>
    </div>
  )
}
