import React from "react"

export default (props) => {
  const _props = {
    className:
      ["my-0", "mt-10", "min-h-1/2", "flex", "flex-col", "justify-center",
        props.className].join(' ')
  }

  if (props.id) {
    _props.id = props.id
  }

  return <div
    {..._props}
  >
    {props.children}
  </div>
}
